import React, { useEffect, useState } from 'react';

function Me() {
    const [me, setMe] = useState([]);

    useEffect(() => {
        const getMe = async () => {
            const resp = await fetch('/api/me');
            const meResp = await resp.json();
            setMe(meResp);
        };

        getMe();
    }, []);

    return (
        <div>
            <h2>About Me</h2>
            <p><strong>Name: </strong>{me.name}</p>
            <p><strong>GitHub: </strong><a href={me.githubURL}>{me.githubURL}</a></p>
            <p><strong>Interesting fact: </strong>{me.interestingFact}</p>
            <p><strong>Skills: </strong>{me.skills}</p>
        </div>
    );
};

export default Me;