import Me from './components/me';
import Organization from './components/organization';

function App() {
    return (
        <div>
            <h1>Hiring Assignment</h1>
            See README.md for more information!
            <Me />
            <hr />
            <Organization />
        </div>
    );
}

export default App;