
import React, { useEffect, useState } from 'react';

function Organization() {
    const [organization, setOrganization] = useState([]);

    useEffect(() => {
        const getOrganization = async () => {
            const resp = await fetch('/api/organization');
            const organizationResp = await resp.json();
            console.log(organizationResp);
            setOrganization(organizationResp);
        };

        getOrganization();
    }, []);

    return (
        <div>
            <h2>Organization</h2>
            {JSON.stringify(organization)}
        </div>
    );
};

export default Organization;